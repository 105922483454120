import React from "react";
import Header from "./components/Header";

import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
const Home = (props) => {
	const navigate = useNavigate();
	return (
		<>
			<div
				style={{
					backgroundImage: 'url("/assets/home/Home-banner@2x.png")',
					backgroundSize: "cover",
					position: "relative",
					// display: "none"
				}}
			>
				{/* <img
					src="/assets/home/Home-banner@2x.png"
					style={{ width: "100%", opacity: 0, verticalAlign: "top" }}
					alt="banner"
				/> */}
				<div
					style={{
						position: "absolute",
						top: 0,
						width: "100%",
						height: "100%"
					}}
				>
					{/* <Header /> */}
					<Container
						fluid
						style={{
							paddingLeft: "50px",
							paddingRight: "50px",
							minHeight: "80vh",
							display: "flex",
							alignItems: "center"
						}}
					>
						{/* <Row>
							<Col md={6}>
								<h3
									style={{
										color: "white",
										fontSize: 80,
										fontFamily: "Bebas"
									}}
								>
									A better life starts with a beautiful smile.
								</h3>

								<NavButton style={{ marginTop: 30 }}>Contact Us</NavButton>
							</Col>
						</Row> */}
					</Container>
				</div>
			</div>
			<Container fluid style={{ minHeight: "100vh" }}>
				<Row>
					<SectionHeader
						// section={"CAYLIEH DENTAL CLINIC"}
						description={"Welcome to our Clinic"}
						// long={
						// 	"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
						// }
					/>
					<Col md={12} className="text-center">
						<img src="assets/home/About us.png" width="80%" alt="" />
					</Col>
					<Col className="text-center" style={{ marginBottom: "100px" }}>
						<NavButton>KNOW MORE ABOUT US</NavButton>
					</Col>
				</Row>
			</Container>
			<Container
				fluid
				style={{ minHeight: "100vh", backgroundColor: "#008F5010" }}
			>
				<Row>
					<SectionHeader
						section={"OUR SERVICES"}
						description={"What we offers"}
						// long={
						// 	"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
						// }
					/>
					<Col md={12}>
						<Row>
							<Col md={6}>
								<img
									src="assets/home/Service-1.png"
									width="70%"
									alt=""
									style={{ float: "right" }}
								/>
							</Col>
							<Col md={6}>
								<img
									src="assets/home/Service-2.png"
									width="70%"
									style={{ float: "left" }}
								/>
							</Col>
							<Col md={6}>
								<img
									src="assets/home/Service-3.png"
									width="70%"
									style={{ float: "right" }}
								/>
							</Col>
							<Col md={6}>
								<img
									src="assets/home/Service-4.png"
									width="70%"
									style={{ float: "left" }}
								/>
							</Col>
						</Row>
					</Col>
					<Col className="text-center mt-5" style={{ marginBottom: "130px" }}>
						<NavButton>CHECK OUR SERVICES</NavButton>
					</Col>
				</Row>
			</Container>
			<div style={{ position: "relative" }}>
				<img
					src="assets/home/Contact us.png"
					width="100%"
					style={{ marginTop: "-30px" }}
				/>
				<div
					style={{
						position: "absolute",
						top: 0,
						padding: "50px",
						paddingLeft: "70px"
					}}
				>
					<h1
						style={{ fontFamily: "Bebas", fontSize: "70px", color: "#008F50" }}
					>
						CONTACT US
					</h1>
					<p style={{ fontSize: "20px" }}>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit,
						<br /> sed do eiusmod tempor incididunt ut labore et dolore magna
						aliqua.
					</p>
					<table>
						<tr>
							<td>
								<img src="assets/home/Icon-Location.png" width="50px" />
							</td>
							<td className="fw-bold ps-3" style={{ fontSize: "20px" }}>
								69-D Rd 1, Project 6 Quezon City
								<br />
								1100 Metro Manila
							</td>
						</tr>
						<tr>
							<td className="pt-3">
								<img src="assets/home/Icon-Fb.png" width="50px" />
							</td>
							<td className="fw-bold ps-3 pt-3" style={{ fontSize: "20px" }}>
								+63945-891-5937
							</td>
						</tr>
					</table>
				</div>
			</div>
			<div className="text-center mt-3 mb-3">
				POWERED BY:{" "}
				<a href="https://alapaapp.tech" target="_blank">
					<img src="alapaapp_logo_4@4x.png" style={{ width: "200px" }} />
				</a>
			</div>
		</>
	);
};

const SectionHeader = (props) => {
	return (
		<>
			<Col md={12}>
				<h2
					className="text-center mt-5"
					style={{ color: "#FFC535", fontFamily: "Bebas" }}
				>
					{props.section}
				</h2>
				<h1 className="text-center mt-3 fw-bold" style={{ color: "#008f50" }}>
					{props.description}
					<span
						style={{
							width: "4%",
							marginLeft: "48%",
							borderTop: "2px solid black",
							marginTop: 14,
							display: "block",
							textAlign: "center"
						}}
					></span>
				</h1>
			</Col>
			<Col md={{ span: 4, offset: 4 }}>
				<p
					className="text-center mt-2"
					style={{ color: "#9D9D9D", fontSize: 20 }}
				>
					{props.long}
				</p>
			</Col>
			<Col md={4}></Col>
		</>
	);
};
const NavButton = (props) => {
	const styles = props.style ?? {};
	const navigate = useNavigate();
	return (
		<button
			className="nav-custom-button"
			style={{
				fontSize: 32,
				fontFamily: "Bebas",
				padding: "4px 16px 4px 26px",
				border: "none",
				borderRadius: 50,
				backgroundColor: "#FFC535",
				...styles
			}}
			onClick={props.onClick ?? (() => navigate("/services"))}
		>
			{props.children}
			<FontAwesomeIcon
				icon={faChevronCircleRight}
				style={{ marginLeft: 10, color: "#008F50" }}
			/>
		</button>
	);
};
export default Home;
