import { Grid } from "@mui/material";
import React from "react";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Header = (props) => {
	const navigate = useNavigate();
	return (
		<Container fluid style={{ paddingLeft: "50px", paddingRight: "50px" }}>
			<Navbar>
				<Container fluid>
					<Navbar.Brand
						href="#home"
						style={{ fontSize: 50, fontWeight: "bold", fontFamily: "Bebas" }}
					>
						Caylieh Dental Clinic
					</Navbar.Brand>
					<Nav className="float-end">
						<Nav.Link
							className="text-dark fw-bold"
							onClick={(e) => {
								e.preventDefault();
								navigate("/");
							}}
						>
							Home
						</Nav.Link>
						<Nav.Link
							className="text-dark fw-bold"
							onClick={(e) => {
								e.preventDefault();
								navigate("/about-us");
							}}
						>
							About Us
						</Nav.Link>
						<Nav.Link href="#pricing" className="text-dark fw-bold">
							Services
						</Nav.Link>
						<Nav.Link href="#pricing" className="text-dark fw-bold">
							Contact Us
						</Nav.Link>
					</Nav>
				</Container>
			</Navbar>
		</Container>
		// <div style={{ display: "flex", flexDirection: "row" }}>
		//   <div>Caylieh Dental Clinic</div>
		//   <div>
		//     <ul style={{listStyleType:'none'}}>
		//       <li>Home</li>
		//       <li>About Us</li>
		//       <li>Services</li>
		//       <li>Contact us</li>
		//     </ul>
		//   </div>
		// </div>
	);
};

export default Header;
