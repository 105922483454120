import React from "react";
import Header from "./components/Header";

import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
const About = (props) => {
	return (
		<>
			<div
				style={{
					backgroundImage: 'url("/assets/about-us/About Dr. Lim - bg.png")',
					backgroundSize: "cover",
					width: "100%",
					height: "200px",
					display: "flex",
					flexDirection: "column"
				}}
			>
				<Header />
				<div>
					<h1
						style={{
							fontFamily: "Bebas",
							textDecoration: "underline",
							fontWeight: "bold",
							color: "rgb(0, 143, 80)",
							textAlign: "center"
						}}
					>
						ABOUT US
					</h1>
				</div>
			</div>
			<Container fluid>
				<Row>
					<SectionHeader
						section={"CAYLIEH DENTAL CLINIC"}
						description={"Welcome to our Clinic"}
						long={
							"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
						}
					/>
					<Col md={12} className="text-center">
						<img src="assets/about-us/About us.png" width="80%" alt="" />
					</Col>
				</Row>
			</Container>
			<Container
				fluid
				style={{
					minHeight: "500px",
					backgroundImage: 'url("/assets/about-us/About Dr. Lim - bg.png")',
					backgroundSize: "cover",
					marginTop: "100px",
					paddingLeft: "70px",
					paddingRight: "70px"
				}}
			>
				<Row>
					<Col md={6} className="text-center">
						<h3
							className="text-center mt-5"
							style={{ color: "#FFC535", fontFamily: "Bebas" }}
						>
							General Dentistry • Orthodontics • Oral Surgery • Esthetics •
							Implant
						</h3>
						<h1
							className="text-center mt-3 fw-bold"
							style={{ color: "#008f50" }}
						>
							DR. CHRISTINE G. LIM
							<span
								style={{
									width: "4%",
									marginLeft: "48%",
									borderTop: "2px solid black",
									marginTop: 14,
									display: "block",
									textAlign: "center"
								}}
							></span>
						</h1>

						<p
							className="text-center mt-2"
							style={{
								color: "#9D9D9D",
								fontSize: 20,
								textAlign: "center"
							}}
						>
							Dr. Christine Lim is a dedicated and compassionate dentist
							committed to providing exceptional dental care. With extensive
							experience in General Dentistry, Orthodontics, Oral Surgery,
							Esthetics, and Implantology, Dr. Lim ensures that each patient
							receives personalized and comprehensive treatment. Passionate
							about enhancing smiles and improving oral health, Dr. Lim stays
							updated with the latest advancements in dental technology and
							techniques. Whether you need routine dental check-ups, orthodontic
							treatment, surgical procedures, cosmetic enhancements, or dental
							implants, Dr. Lim and the team are here to deliver outstanding
							care in a comfortable and friendly environment.{" "}
							<b>Your smile is our priority!</b>
						</p>
					</Col>
					<Col md={6}>
						<img
							src="assets/about-us/About Dr. Lim@2x.png"
							width="100%"
							alt=""
						/>
					</Col>
				</Row>
			</Container>
			<Container>
				<SectionHeader
					section={"CAYLIEH DENTAL CLINIC"}
					description={"Our beloved patients"}
					long={
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
					}
				/>
			</Container>

			{/* <div className="text-center mt-3 mb-3">
				POWERED BY:{" "}
				<a href="https://alapaapp.tech" target="_blank">
					<img src="alapaapp_logo_4@4x.png" style={{ width: "200px" }} />
				</a>
			</div> */}
		</>
	);
};

const SectionHeader = (props) => {
	return (
		<>
			<Col md={12}>
				<h2
					className="text-center mt-5"
					style={{ color: "#FFC535", fontFamily: "Bebas" }}
				>
					{props.section}
				</h2>
				<h1 className="text-center mt-3 fw-bold" style={{ color: "#008f50" }}>
					{props.description}
					<span
						style={{
							width: "4%",
							marginLeft: "48%",
							borderTop: "2px solid black",
							marginTop: 14,
							display: "block",
							textAlign: "center"
						}}
					></span>
				</h1>
			</Col>
			<Col md={{ span: 4, offset: 4 }}>
				<p
					className="text-center mt-2"
					style={{ color: "#9D9D9D", fontSize: 20 }}
				>
					{props.long}
				</p>
			</Col>
			<Col md={4}></Col>
		</>
	);
};
const NavButton = (props) => {
	const styles = props.style ?? {};
	return (
		<button
			className="nav-custom-button"
			style={{
				fontSize: 32,
				fontFamily: "Bebas",
				padding: "4px 16px 4px 26px",
				border: "none",
				borderRadius: 50,
				backgroundColor: "#FFC535",
				...styles
			}}
		>
			{props.children}
			<FontAwesomeIcon
				icon={faChevronCircleRight}
				style={{ marginLeft: 10, color: "#008F50" }}
			/>
		</button>
	);
};
export default About;
